import React, { useState } from "react";
import AuthLayout from "../components/ui/layouts/auth";
import { InputField, PasswordField } from "../components/ui/form-elements";
import { AppBtn } from "../components/ui/buttons";
import Link from "next/link";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoginParams } from "../api/interfaces/auth.interface";
import { getFieldvalues } from "../assets/js/utils/functions";
import authContext from "../contexts/auth-context";
import ErrorLabel from "../components/ui/error-label";

const Login = () => {
  const { login, loginRequest } = authContext.useContainer();
  const { isLoading } = loginRequest;
  const [errorText, setErrorText] = useState<string>(null);

  const form = useFormik<LoginParams>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setErrorText("");
      const [response, error] = await login(values);
      if (error) {
        setErrorText(error.message);
      }
    },
  });

  return (
    <AuthLayout tags={seoTags}>
      <div className="w-full">
        <figure className="h-18 w-18 sm:h-20 sm:w-20 rounded-full bg-accent-orange-500 m-auto flex items-center justify-center text-white">
          {/* prettier-ignore */}
          <svg className="w-[30px] sm:w-8" viewBox="0 0 30 30" fill="none">
            <path d="M27.9501 10.3375L27.5875 6.875C27.0625 3.1 25.3501 1.5625 21.6876 1.5625H18.7376H16.8876H13.0876H11.2376H8.23755C4.56255 1.5625 2.86255 3.1 2.32505 6.9125L1.98755 10.35C1.86255 11.6875 2.22505 12.9875 3.01255 14C3.96255 15.2375 5.42505 15.9375 7.05005 15.9375C8.62505 15.9375 10.1376 15.15 11.0876 13.8875C11.9376 15.15 13.3876 15.9375 15.0001 15.9375C16.6126 15.9375 18.0251 15.1875 18.8876 13.9375C19.8501 15.175 21.3375 15.9375 22.8875 15.9375C24.55 15.9375 26.05 15.2 26.9875 13.9C27.7375 12.9 28.0751 11.6375 27.9501 10.3375Z" fill="white"/>
            <path d="M14.1874 20.825C12.5999 20.9875 11.3999 22.3375 11.3999 23.9375V27.3625C11.3999 27.7 11.6749 27.975 12.0124 27.975H17.9749C18.3124 27.975 18.5874 27.7 18.5874 27.3625V24.375C18.5999 21.7625 17.0624 20.525 14.1874 20.825Z" fill="white"/>
            <path d="M26.7126 18V21.725C26.7126 25.175 23.9126 27.975 20.4626 27.975C20.1251 27.975 19.8501 27.7 19.8501 27.3625V24.375C19.8501 22.775 19.3626 21.525 18.4126 20.675C17.5751 19.9125 16.4376 19.5375 15.0251 19.5375C14.7126 19.5375 14.4001 19.55 14.0626 19.5875C11.8376 19.8125 10.1501 21.6875 10.1501 23.9375V27.3625C10.1501 27.7 9.8751 27.975 9.5376 27.975C6.0876 27.975 3.2876 25.175 3.2876 21.725V18.025C3.2876 17.15 4.1501 16.5625 4.9626 16.85C5.3001 16.9625 5.6376 17.05 5.9876 17.1C6.1376 17.125 6.3001 17.15 6.4501 17.15C6.6501 17.175 6.8501 17.1875 7.0501 17.1875C8.5001 17.1875 9.9251 16.65 11.0501 15.725C12.1251 16.65 13.5251 17.1875 15.0001 17.1875C16.4876 17.1875 17.8626 16.675 18.9376 15.75C20.0626 16.6625 21.4626 17.1875 22.8876 17.1875C23.1126 17.1875 23.3376 17.175 23.5501 17.15C23.7001 17.1375 23.8376 17.125 23.9751 17.1C24.3626 17.05 24.7126 16.9375 25.0626 16.825C25.8751 16.55 26.7126 17.15 26.7126 18Z" fill="white"/>
          </svg>
        </figure>
        <h2 className="text-center font-light text-black text-2lg sm:text-[26px] lg:text-3xl mx-auto mt-3.5 !leading-tight">
          Welcome back,
          <br />
          <b className="font-bold">Login to your account</b>
        </h2>
        <form onSubmit={form.handleSubmit} className="mt-10 sm:mt-12.5 w-full">
          <ErrorLabel error={errorText} perm />
          <InputField label="Your email" {...getFieldvalues("email", form)} />
          <PasswordField label="Your password" type="password" {...getFieldvalues("password", form)} />
          <div className="flex items-center">
            <Link href="/reset-password">
              <a className="inline-block text-1xs mt-2.5 font-semibold text-primary-500 ml-auto">Forgot password?</a>
            </Link>
          </div>
          <AppBtn color="primary" isBlock className="mt-8" type="submit" disabled={isLoading} size="lg">
            {isLoading ? "Logging in..." : "Login"}
          </AppBtn>
          <div className="text-center text-sm text-dark mt-2.5">
            New to catlog?{" "}
            <Link href="/sign-up">
              <a className="inline-block font-semibold text-primary-500">Create an account</a>
            </Link>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

const seoTags = {
  title: "Login - Catlog",
  description: "Login to your catlog dashboard",
  pageUrl: "/login",
  image: "https://res.cloudinary.com/catlog/image/upload/v1674314911/seo-banners/Dashboard_Banner.png",
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Email Address is required"),
  password: Yup.string().required("Password is required"),
});

export async function getStaticProps(context) {
  return {
    props: {}, // will be passed to the page component as props
  };
}

export default Login;
