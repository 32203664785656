import DisplayText from "./display-text";
import { getStyles } from "../../../../assets/js/utils/functions";
import SEOTags, { PageTags } from "../../../seo-tags";

const AuthLayout: React.FC<{ tags: PageTags }> = ({ children, tags }) => {
  return (
    <>
      <SEOTags tags={tags} />
      <div className="auth-pages-bg min-h-screen w-full flex flex-col items-center relative pb-11.25 sm:pb-15">
        <figure className="mx-auto h-6.25 sm:h-7 hidden md:block px-3.75 mt-11.25 sm:mt-15">
          <img src="/images/new-logos/white.svg" alt="Catlog logo white" className="h-full" />
        </figure>
        <header className="w-full border-b border-grey-border border-opacity-50 flex items-center md:hidden p-5">
          <a className="h-6">
            <img src="/images/new-logos/dark.svg" alt="Catlog logo" className="h-full" />
          </a>
        </header>
        <div className="md:py-10 px-5 sm:px-10 lg:px-15 bg-white rounded-15 w-full max-w-[500px] mt-11.25 sm:mt-15">
          <div className="flex items-center flex-col">{children}</div>
        </div>
      </div>
      {/* <main className="min-h-[90vh] md:min-h-screen md:h-screen overflow-y-hidden w-full flex flex-col md:grid grid-cols-1 md:grid-cols-[minmax(375px,0.4fr),minmax(400px,1fr)]">
        <div className="w-full bg-pattern-light bg-cover h-full hidden md:flex flex-col md:px-8 py-9 lg:px-11.25 lg:py-11.25 relative overflow-hidden">
          <a className="h-7 z-10">
            <img src="/images/new-logos/dark.svg" alt="Catlog logo" className="h-full" />
          </a>
          <div className="md:mt-12 lg:mt-16 w-full max-w-[350px] flex flex-col z-10">
            <DisplayText />
          </div>
          <figure className="absolute bottom-[-18px] left-[-13%] w-[130%] block">
            <img src="/images/storefront.png" alt="Catlog logo" className="w-full" />
          </figure>
        </div>
        <header className="w-full border border-grey-border border-opacity-50 flex items-center md:hidden p-5">
          <a className="h-6">
            <img src="/images/new-logos/dark.svg" alt="Catlog logo" className="h-full" />
          </a>
        </header>
        <div className="sm:min-h-full px-5 sm:px-10 lg:px-16.25 overflow-y-auto flex-1 relative">
          <div
            className="w-full max-w-[410px] anim-fade-up flex flex-col items-center justify-center py-20 mx-auto relative min-h-full"
            style={getStyles({ "--delay": "0.5s" })}
          >
            {children}
          </div>
        </div>
      </main> */}
    </>
  );
};

export default AuthLayout;
